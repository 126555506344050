import { defineStore } from "pinia";
import * as initiativesActions from "./actions";

export const useInitiativesStore = defineStore("initiatives", {
  state: () => {
    return {
      initiatives: [],
      initiativeTemplates: [],
      currentInitiative: null,
    };
  },
  actions: {
    async fetchInitiatives(data: any) {
      await initiativesActions.fetchInitiatives(this.$state, data);
    },
    async fetchInitiative(data: any) {
      await initiativesActions.fetchInitiative(this.$state, data);
    },
    async createInitiative(data: any) {
      await initiativesActions.createInitiative(this.$state, data);
    },
    async fetchInitiativeTemplates() {
      await initiativesActions.fetchInitiativeTemplates(this.$state);
    },
    async fetchInitiativeTemplate(data: any) {
      return await initiativesActions.fetchInitiativeTemplate(this.$state, data);
    },
    async copyInitiativeFromTemplate(data: any) {
      await initiativesActions.copyInitiativeFromTemplate(this.$state, data);
    },
  },
});

export default useInitiativesStore;
