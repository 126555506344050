// useNotify.ts

import { getCurrentInstance } from "vue";

export function useNotify() {
  const instance = getCurrentInstance();
  const $notify = instance?.appContext.config.globalProperties.$notify;

  if (!$notify) {
    throw new Error("No global $notify function found.");
  }

  return $notify;
}
