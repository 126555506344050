export async function fetchInitiatives(state: any, payload: { [key: string]: any } = {}) {
  // Check if payload is provided and is not null
  if (!payload) {
    console.error("Payload is undefined or null");
    return;
  }

  // Construct the URL with parameters
  const params = new URLSearchParams(Object.entries(payload));
  const apiUrl = `${process.env.VUE_APP_API_URL}/api/initiatives?${params.toString()}`;

  try {
    const response = await fetch(apiUrl);

    // Check if the response is okay
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (data) {
      state.initiatives = data; // Assuming you have an initiatives property in your state
    }
  } catch (err) {
    console.error("Error fetching initiatives:", err);
  }
}

// Fetch a single initiative by ID
export async function fetchInitiative(state: any, initiativeId: string) {
  const apiUrl = `${process.env.VUE_APP_API_URL}/api/initiatives/${initiativeId}`;

  try {
    const response = await fetch(apiUrl);

    // Check if the response is okay
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const initiative = await response.json();

    if (initiative) {
      // Assuming you have a currentInitiative property in your state
      state.currentInitiative = initiative;
    }
  } catch (err) {
    console.error(`Error fetching initiative with ID ${initiativeId}:`, err);
  }
}

// Create a new initiative
export async function createInitiative(state: any, payload: { [key: string]: any }) {
  const apiUrl = `${process.env.VUE_APP_API_URL}/api/initiatives`;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    // Check if the response is okay
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (data) {
      state.initiatives.push(data); // Assuming you have an initiatives property in your state that is an array
    }
  } catch (err) {
    console.error("Error creating initiative:", err);
  }
}

/**
 * Fetches the list of initiative templates.
 * @param {any} state - The state of the store
 */
export async function fetchInitiativeTemplates(state: any) {
  const apiUrl = `${process.env.VUE_APP_API_URL}/api/initiatives/templates`;

  try {
    const response = await fetch(apiUrl);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const templates = await response.json();

    if (templates) {
      state.initiativeTemplates = templates; // Assuming you have an initiativeTemplates property in your state
    }
  } catch (err) {
    console.error("Error fetching initiative templates:", err);
  }
}

// Fetch a single initiative template by ID
export async function fetchInitiativeTemplate(state: any, templateId: string) {
  const apiUrl = `${process.env.VUE_APP_API_URL}/api/initiatives/templates/${templateId}`;

  try {
    const response = await fetch(apiUrl);

    // Check if the response is okay
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const template = await response.json();

    if (template) {
      // Assuming you have a currentInitiativeTemplate property in your state
      state.currentInitiativeTemplate = template;
    }

    return template;
  } catch (err) {
    console.error(`Error fetching initiative template with ID ${templateId}:`, err);
  }
}

/**
 * Copies an initiative template to create a new initiative.
 * @param {any} state - The state of the store
 * @param {Object} payload - The payload object containing the templateId, userId, and companyId
 */
export async function copyInitiativeFromTemplate(
  state: any,
  payload: { templateId: string; userId: string; companyId: string }
) {
  const { templateId, userId, companyId } = payload;
  const apiUrl = `${process.env.VUE_APP_API_URL}/api/initiatives/createFromTemplate/${templateId}`;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, companyId }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const newInitiative = await response.json();

    if (newInitiative) {
      state.initiatives.push(newInitiative); // Assuming you have an initiatives property in your state that is an array
    }
  } catch (err) {
    console.error("Error copying initiative from template:", err);
  }
}
