<template>
  <div class="relative">
    <div
      :class="[
        'rounded-sm whitespace-nowrap py-1 border border-transparent flex items-center justify-center cursor-pointer',
        getStatusClass(status),
      ]"
      @click.stop="toggleStatus"
      :id="referenceId"
    >
      <span class="text-xs font-medium text-center">{{ status }}</span>
    </div>
    <div
      :class="[
        'status-menu-class rounded-sm whitespace-nowrap bg-white p-1 shadow-md absolute z-20 mt-1 border w-full flex-col gap-1',
        open ? 'flex' : 'hidden',
      ]"
      :id="elementId"
    >
      <div
        v-for="status in statuses"
        :key="status.label"
        :class="[
          'status-menu rounded-sm whitespace-nowrap py-1 border border-transparent flex items-center justify-center cursor-pointer hover:opacity-75',
          getStatusClass(status.label),
        ]"
        @click.stop="changeStatus($event.target.innerText)"
      >
        <span class="text-xs font-medium text-center">{{ status.label }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, onMounted, onUnmounted, watch } from "vue";

const props = defineProps({
  status: {
    type: String,
    required: true,
  },
  statuses: {
    type: Array,
    required: true,
    default: () => [
      { label: "Not Started", class: "bg-gray-100 text-gray-400" },
      { label: "In Progress", class: "bg-yellow-100 text-yellow-600" },
      { label: "Completed", class: "bg-green-100 text-green-600" },
      { label: "Blocked", class: "bg-red-100 text-red-600" },
    ],
  },
  open: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["change", "toggle"]);
const referenceId = ref(null);
const elementId = ref(null);

function getStatusClass(status) {
  let returnClass = "px-2 py-0.5 hover:opacity-75 ";
  const statusObj = props.statuses.find((s) => s.label === status);
  if (!statusObj || statusObj.color === undefined) {
    returnClass += "bg-gray-100 text-gray-400";
  } else {
    switch (statusObj.color) {
      case "gray":
        returnClass += "bg-gray-100 text-gray-400";
        break;
      case "yellow":
        returnClass += "bg-yellow-100 text-yellow-600";
        break;
      case "blue":
        returnClass += "bg-blue-100 text-blue-600";
        break;
      case "green":
        returnClass += "bg-green-100 text-green-600";
        break;
      case "red":
        returnClass += "bg-red-100 text-red-600";
        break;
      default:
        returnClass += "bg-gray-100 text-gray-400";
    }
  }

  return returnClass;
}

// Watch the open prop for changes
watch(
  () => props.open,
  (newOpen, oldOpen) => {
    if (newOpen) {
      // Move the element below the reference element
      const element = document.getElementById(elementId.value);
      const reference = document.getElementById(referenceId.value);
      moveElementBelowReference(element, reference);
    } else {
      // Place the element back
      const element = document.getElementById(elementId.value);
      placeElementBack(element);
    }
  }
);

function changeStatus(status) {
  emit("change", status);
}

function toggleStatus() {
  emit("toggle");
}

function generateRandomUUID() {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
}

// Hoist the menu so that it can overflow its containers
const maxWidth = computed(() => {
  const maxWidthPerChar = 8; // Set a fixed width per character, adjust as needed
  const padding = 16; // Total horizontal padding, adjust as needed
  let maxLabelLength = 0;

  props.statuses.forEach((status) => {
    if (status.label.length > maxLabelLength) {
      maxLabelLength = status.label.length;
    }
  });

  return `${maxLabelLength * maxWidthPerChar + padding}px`;
});

function moveElementBelowReference(element, reference) {
  if (!element || !reference) return;

  // Store the original position
  element.originalParent = element.parentNode;
  element.originalNextSibling = element.nextSibling;

  element.style.width = maxWidth.value;
  element.style.zIndex = 9999999;
  element.style.minWidth = "125px";

  // Calculate position relative to the reference element
  const refRect = reference.getBoundingClientRect();
  element.style.position = "fixed";
  element.style.top = `${window.scrollY + refRect.bottom}px`; // Adjust for scrolling
  element.style.left = `${refRect.left}px`;

  // Move the element
  document.body.appendChild(element);
}

function placeElementBack(element) {
  if (!element || !element.originalParent) return;

  // Restore the original position
  element.style.position = "";
  element.style.top = "";
  element.style.left = "";

  if (element.originalNextSibling) {
    element.originalParent.insertBefore(element, element.originalNextSibling);
  } else {
    element.originalParent.appendChild(element);
  }

  // Clean up
  element.originalParent = null;
  element.originalNextSibling = null;
}

function closeDropdown() {
  if (props.open) {
    toggleStatus();
  }
}

onMounted(() => {
  elementId.value = generateRandomUUID();
  referenceId.value = generateRandomUUID();
  const elements = document.querySelectorAll("body, .modal-body");
  elements.forEach((el) => {
    el.addEventListener("click", () => {
      closeDropdown();
    });
  });
});

onUnmounted(() => {
  const elements = document.querySelectorAll("body, .modal-body");
  elements.forEach((el) => {
    el.removeEventListener("click", toggleStatus);
  });
});
</script>

<style></style>
